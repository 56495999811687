<!--
 * @Author: hwu
 * @Date: 2021-01-20 22:49:09
 * @Description: 通用的错误页面
 * @LastEditTime: 2021-01-20 23:03:49
-->

<template>
  <div class="app-container">
    <div class="wh-empty-box">
      <img class="wh-empty-img" src="@/assets/images/empty-1.png">
      <p class="wh-empty-text">{{errMsg || '服务异常，请关闭浏览器重新操作'}}</p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      errMsg: ''
    }
  },
  created () {
    this.errMsg = this.$route.query.msg || ''
  }
}
</script>
<style lang="scss" scoped>

</style>
